.dotted-line {
    border-bottom: 2px dotted #098B9A;
}

#sticky-cta form input {
    min-width: 320px;
}

.step-title {
    font-size: 22px;
}

.steps-card {
    width: 30%;
}

.m-form-search {
    max-width: 420px;
    margin: 0 auto;
}

.steps-icon {
    width: 69px;
    height: 69px;
}

.dotted-box {
    border-top: 2px dotted #098B9A;
}

.steps-border {
    border-color: #098B9A;
}

.steps-card:nth-child(7),
.steps-card:nth-child(9) {
    padding-top: 74px;
}

.m-form-search__input::placeholder {
    color: #B3AFB0;
}